<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css">

<section class="section banner strongGradientBackground">
  <div class="row justify-content-around">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div>
        <div>
          <h2 class="defaultText">Support your Favorite Podcasters, and get access to their Exclusive / Ad-Free Podcasts</h2>
        </div>
        <div class="padding20">
          <!-- Download on App Store Button -->
          <a class="buttonMargin" href="itms-apps://itunes.apple.com/app/apple-store/id1520783988?mt=8">
            <img src="/assets/downloadOnAppStore.svg" alt="Download On App Store">
          </a>
          <a class="btn pdrButton buttonMargin marginTop10" href="https://creator.podder.us/register" role="button">Claim your Podcast Now!</a>
        </div>
      </div>
    </div>
    <div class="d-md-block col-md-4 order-0 order-md-1">
      <img src="/assets/screenShot.png" alt="Podder Feed Screenshot" class="responsiveImage" style="max-width: 250px;">
    </div>
  </div>
</section>

<section class="section">
  <div class="row text-center">
    <div class="col-12 pb-4">
      <h2 class="headerText">The Basics</h2>
    </div>

    <div class="col-12 flexCenter">
      <div class="width300">
        <span class="fa-stack fa-2x">
          <i class="fas fa-circle fa-stack-2x orangeText"></i>
          <i class="fas fa-headphones fa-stack-1x defaultText"></i>
        </span>
        <h3 class="mt-3 headerText h4">Listen</h3>
        <p class="defaultText">Enjoy your favorite Podcasts</p>
      </div>
      <div class="width300">
        <span class="fa-stack fa-2x">
          <i class="fas fa-circle fa-stack-2x orangeText"></i>
          <i class="fas fa-donate fa-stack-1x defaultText"></i>
        </span>
        <h3 class="mt-3 headerText h4">Contribute</h3>
        <p class="defaultText">Set up a monthly Subscription, 
          <br>or donate to your favorite Podcasters individually</p>
      </div>
      <div class="width300">
        <span class="fa-stack fa-2x">
          <i class="fas fa-circle fa-stack-2x orangeText"></i>
          <i class="fas fa-unlock fa-stack-1x defaultText"></i>
        </span>
        <h3 class="mt-3 headerText h4">Unlock Premium Podcasts</h3>
        <p class="defaultText">Your Subscription unlocks all Premium Feeds,
          <br>Indiviudal Contributions unlock Feeds for that specific Creator</p>
      </div>
    </div>
  </div>
</section>

<section class="section reverseGradientBackground">
  <div class="row">
    <div class="col-12 col-md-6 d-flex align-items-center order-1 order-md-0">
      <div class="p-15">
        <h3 class="orangeText">Mission</h3>
        <p>Our mission at Podder is fairly simple: Make it as easy as possible for listeners to support their favorite Podcasters.
        <br>We aim to do this by giving you multiple ways to contribute to creators, and in return, having you get access to additional content:</p>
        
        <p><strong class="headerText">Monthly Subscriptions</strong>
        <br>Your Monthly Subscription will be distributed among your favorite podcasters based on your listeneing habits.
        <br><strong>E.g.</strong> My monthly Subscription is 7$, this Month I listened to Joe for 4 hours and Scott for 3 hours: Joe will get 4$ and Scott will get 3$ (before Fees, see below for detail)
        <br>A Monthly Subscription will unlock all Premium Podcasts on Podder.</p>

        <p><strong class="headerText">One-Off Contribution</strong>
        <br>Donate a select amount to a Podcaster of your choice, just that one time.
        A One-Off Contribution will only unlock that specific Creator's Premium Podcasts for a Month.</p>

        <p><strong class="headerText">Fees</strong>
        <br>For our Fee structure we tried to be as unintrusive as possible, after all the service is about Podcasters and their Listeners, and not about us. 
        <br>The Fee we're starting with is 5%, on top of Billing Fees*. We think this is enough to pay our Staff and cover Server Costs.
        <br>As we grow, we will continuously re-evaluate the Fee structure (and hopefully lower these Fees even further).
        <br>*Billing Fees can be anywhere from 5% to 15% given the Payment Method. This Fee is identical for Monthly Subscriptions and One-Off Contributions.</p>
      </div>
    </div>
    <div class="d-md-block col-md-6 order-0 order-md-1">
      <img src="/assets/screenShotProfile.png" alt="Podder Profile Screenshot" class="responsiveImage" style="padding-top: 30px; padding-bottom: 30px;">
    </div>
  </div>
</section>

<section class="section secondaryBackground">
  <div class="row no-gutters">
    <div class="d-md-block col-md-6 order-2">
      <img src="/assets/screenShotEpisode.png" alt="Podder Profile Screenshot" class="responsiveImage" style="float: center;">
    </div>
    <div class="col-12 col-md-6 d-flex align-items-center order-3">
      <div class="p-15">
        <h3 class="orangeText">Privacy</h3>
        <p>At Podder we're trying to take a more ethical apporach towards Privacy. Simply put, we believe that your Data is just that, <strong>your</strong> Data. We will never sell your Data - it isn't ours to sell.
        <br>We believe that taking a small fee from contributions should cover all our costs, and in turn ensure your privacy is protected.</p>
        <p>What we will do with listening data is ensure that Podcasters get compensated adequately, and give Podcasters a general sense of how popular their Podcasts are. E.g. 20 people listened to your first Episode, and 60 people listened to Episode 2. 
        <br>However any party, including Podcasters, asking for the listening data of an individual will be firmly denied.</p>

        <button class="btn pdrButton" routerLink="/terms">Read Full Terms & Conditions →</button>
      </div>
    </div>
  </div>
</section>

<section class="section gradientBackground">
  <div class="row no-gutters">
    <div class="col-12 col-md-6 d-flex align-items-center order-1 order-md-0">
      <div class="p-15">
        <h3 class="orangeText">Philosophy</h3>

        <p><strong class="headerText">Ownership</strong>
          <br>During the process of building Podder there was a firm goal of taking no outside Money. We believe that the Ownership of a Platform built for Podcasters and their Listeners should be in the hands of, <i>you guessed it</i>, Podcasters and their Listeners. The Platform is for you, you will know what is best in terms of the future direction of the Company.</p>
          <p>While it's likely inevitable that we will need outside Money at some point, we put some rules in place to keep this in check. Mainly, we've committed ourselves to a long term goal of <strong>giving Podcasters and Employees ~50% of the Ownership of Podder</strong>. We plan to achieve this by handing out a percentage of the ownership of the Company to our most successful Podcasters every year.</p>

        <p><strong class="headerText">Transparency</strong>
          <br>At Podder we believe the key to good technology is Transparency. Simply put: If you can't tell your Users exactly what you're doing, you probably shouldn't be doing it. 
          <p>As part of this continuous quest for Transparency we will be publishing graphical breakdowns of the ownership of the company, how your Contributions are broken down in terms of Billing versus Service Fees, how those Fees are being used, etc. 
          <br>These Graphs will be published at least once a year on this site, but hopefully more often as things get rolling - You can expect this first set of Graphs by Spring 2021!</p>
      </div>
    </div>
  </div>
</section>

<section class="section reverseGradientBackground">
  <div class="row text-center">
    <div class="col-12 pb-4">
      <h2 class="headerText">Contact</h2>
    </div>

    <div class="col-12 flexCenter">
      <div class="width300">
        <a class="pdrLink" href="mailto:feedback@podder.us">
          <span class="fa-stack fa-2x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-comment-alt fa-stack-1x defaultText"></i>
          </span>
          <h3 class="mt-3 h4">Send us Feedback</h3>
        </a>
      </div>
      <div class="width300">
        <a class="pdrLink" href="https://creator.podder.us/register">
          <span class="fa-stack fa-2x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-sign-in-alt fa-stack-1x defaultText"></i>
          </span>
          <h3 class="mt-3 h4">Set up Contributions for your Podcast</h3>
        </a>
      </div>
      <div class="width300">
        <a class="pdrLink" href="https://twitter.com/podderApp">
          <span class="fa-stack fa-2x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fab fa-twitter fa-stack-1x defaultText"></i>
          </span>
          <h3 class="mt-3 h4">Twitter</h3>
        </a>
      </div>
      <div class="width300">
        <a class="pdrLink" href="https://www.facebook.com/Podder-101963574643042">
          <span class="fa-stack fa-2x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fab fa-facebook fa-stack-1x defaultText"></i>
          </span>
          <h3 class="mt-3 h4">Facebook</h3>
        </a>
      </div>
    </div>
  </div>
</section>
