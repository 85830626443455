import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as $ from "jquery";

declare var grecaptcha: any;

@Component({
  selector: 'app-episode',
  templateUrl: './episode.component.html',
  styleUrls: ['./episode.component.css']
})
export class EpisodeComponent implements OnInit {
	private iOSDevice = true;

	constructor(private http: HttpClient, private route: ActivatedRoute) { }

 	ngOnInit() {
    	let feedID = this.route.snapshot.queryParams.feedID;
 		let episodeID = this.route.snapshot.queryParams.episodeID;
 		if (!feedID) { this.displayError(null); }

 		this.iOSDevice = (/iPad|iPhone|iPod/.test(navigator.platform) || 
 			(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
 		console.log('is IOS: ' + this.iOSDevice);

 		if (typeof grecaptcha === 'undefined') { 
      		console.log('Loading Script');
      		let scriptURL = 'https://www.google.com/recaptcha/api.js?render=6LceO_cUAAAAACE_BAaHLjE43QWx9Xx75A3hcu0L';
      		this.loadReCaptcha(scriptURL, feedID, episodeID);
    	}
    	else { this.loadEpisode(feedID, episodeID); }

    	var uagent = navigator.userAgent.toLowerCase();
  		console.log(uagent);
  	}

  	/**
	 * Load reCaptcha script and add callback to load Episode once script is loaded
	 */
  	loadReCaptcha(url: string, feedID: string, episodeID: string) {
    	const body = <HTMLDivElement> document.body;
   		const script = document.createElement('script');
   		script.innerHTML = '';
    	script.src = url;
    	script.async = true;
    	body.appendChild(script);
    	script.onload = () => { 
    		this.loadEpisode(feedID, episodeID); 
    	};
  	}

  	/**
	 * Load the shared Episode
	 */
  	loadEpisode(feedID: string, episodeID: string) {
  		// Instantiate some variables they'll need
  		let $this: EpisodeComponent = this;
	    let p: any = document.getElementById('loadingText');

	    // Verify they're not a bot
	    grecaptcha.ready(function() {
		    grecaptcha.execute('6LceO_cUAAAAACE_BAaHLjE43QWx9Xx75A3hcu0L', {action: 'episodeShared'}).then(async function(token) {
		      	let options = { headers: { 
		      		'reCaptchaToken' : 	token, 
		      		'feedID' : 			feedID, 
		      		'episodeID' : 		episodeID 
		      	}};
		      	let observable = $this.http.get('http://localhost:3000/publicAPI/getFeedRSSAndEpisodeGUID', options);
		      	observable.subscribe((data: any) => {
		      		console.log(1)
		      		let episodeWebsite = data.episodeWebsite;
		      		if (!episodeWebsite) { $this.displayError(null); }
		      		else if ($this.iOSDevice) { $this.addRedirectLink(episodeWebsite); }
		      		else { window.location.href = episodeWebsite; }
      			}, 
      			(err) => {
	        		// Else show error message
	        		let message = err && err.error && err.error.message ? err.error.message : null;
	        		$this.displayError(message);
	      		});
		    });
	    });
	}

	/**
	 * Let User know there was an Issue loading the Episode
	 */
	displayError(messagePassedIn: string|null) {
		let message = messagePassedIn ? messagePassedIn : "Issue loading Episode, please use App to access this Episode, or try again later.";
	    let p: any = document.getElementById('loadingText');
	    p.innerHTML = message;
	}

	/**
	 * Let User know there was an Issue loading the Episode
	 */
	addRedirectLink(url: string) {
		var message = "Use button above to view in App, or <a href=" + url;
		message += ">click here</a> to go to Episode Website.";
	    let p: any = document.getElementById('loadingText');
	    p.innerHTML = message;
	}
}
