<!-- Link to Download App -->
<div class="episodeLoadContainer">
	<a class="buttonMargin" href="itms-apps://itunes.apple.com/app/apple-store/id1520783988?mt=8">
		<img src="/assets/downloadOnAppStore.svg" alt="Download On App Store">
	</a>
</div>

<!-- Load Episode -->
<div class="episodeLoadContainer">
	<p id="loadingText" class="lead">Loading Podcast...</p>
</div>
