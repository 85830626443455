<nav class="navbar navbar-expand-lg navbar-dark tertiaryBackground" style="height: 60px">
  <!-- Add Branding -->
  <a class="navbarTitle" routerLink="/"> <!-- pdrLink -->
    <!-- <img src="/assets/podderIcon280.png" width="30" height="30" class="d-inline-block align-top" alt=""> -->
    &nbsp;Podder
  </a>

  <!-- Add buttons (that are collapsable under the button above) -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="navBarLink" href="https://creator.podder.us/login">Creators</a>
    </li>
    <li class="nav-item">
      <a class="navBarLink" routerLink="/policy">Policy</a>
    </li>
  </ul>
</nav>

<router-outlet></router-outlet>

<footer class="py-4 page-footer secondaryBackground">
  <div class="container-fluid container-fluid-max">
    <div class="row">
      <div class="col-12 col-md-6 footer-child copyright">
        Podder LLC © 2020 All Rights Reserved
      </div>
      <div class="col-12 col-md-6 footer-child footer-links">
        <a routerLink="/privacy" class="mr-3">Privacy Policy</a>
      </div>
    </div>
  </div>
</footer>
