import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, Params } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { EpisodeComponent } from './episode/episode.component';
import { PolicyComponent } from './policy/policy.component';
import { TermsComponent } from './terms/terms.component';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  { path: '', component: HomeComponent },

  { path: 'contents', component: EpisodeComponent },

  { path: 'policy', component: PolicyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'guidelines', component: GuidelinesComponent },
  { path: 'privacy', component: PrivacyComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EpisodeComponent,
    PolicyComponent,
    TermsComponent,
    GuidelinesComponent,
    PrivacyComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    RouterModule.forRoot(routes),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
